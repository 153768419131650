import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment, lazy, Suspense, useMemo } from 'react';
import { isContent, isLayout, } from '../types/dynamicComponent.types';
import { ComponentController } from './componentController';
const componentList = {
    Carousel: lazy(() => import('@backstage-components/carousel').then(({ CarouselComponent }) => ({
        default: CarouselComponent,
    }))),
    Container: lazy(() => import('@backstage-components/container-layout').then(({ ContainerLayout }) => ({ default: ContainerLayout }))),
    Modal: lazy(() => import('@backstage-components/modal').then(({ ModalComponent }) => ({
        default: ModalComponent,
    }))),
    PeacockBackground: lazy(() => import('@backstage-components/peacock-background').then(({ PeacockBackgroundComponent }) => ({
        default: PeacockBackgroundComponent,
    }))),
    RtcRoomContainer: lazy(() => import('@backstage-components/rtc-room-container').then(({ RtcRoomContainerComponent }) => ({ default: RtcRoomContainerComponent }))),
    Stacked: lazy(() => import('@backstage-components/stack-layout').then(({ StackLayout }) => ({
        default: StackLayout,
    }))),
    Style: lazy(() => import('@backstage-components/style-layout').then(({ StyleLayout }) => ({
        default: StyleLayout,
    }))),
};
export const LayoutController = (definition) => {
    const props = useMemo(() => Object.assign({ slotRenderer: SlotRender }, definition), [definition]);
    if (componentList[definition.component]) {
        // Not sure how to make type of `DefineComponent` match `definition` but
        // `componentList` properly checks that only an appropriate Component can be
        // assigned so should be safe
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const DefinedComponent = componentList[definition.component];
        return (_jsx(Suspense, { fallback: _jsx(Fragment, {}), children: _jsx(DefinedComponent, { ...props }) }));
    }
    else {
        return _jsx("div", { children: definition.component });
    }
};
const SlotRender = (component) => {
    if (isLayout(component)) {
        return _jsx(LayoutController, { ...component });
    }
    else if (isContent(component)) {
        return _jsx(ComponentController, { ...component });
    }
    else {
        return null;
    }
};
