import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RouterContainer } from '@backstage-components/router';
import { useCallback } from 'react';
import { useNavigate as useRouterNavigate, } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
export const UiRouter = (props) => {
    return (_jsx(Router, { children: _jsx(UiRouterInternal, { ...props }) }));
};
const UiRouterInternal = (props) => {
    // `Route` needs to be a direct child of `Router`. `PageRoute` cannot
    // encapsulate `Route` so assemble the `Route` and `PageRoute` elements in
    // multiple passes
    const { prefix, fallbackElement: FallbackComponent, pageRoutes } = props;
    const navigateFn = useRouterNavigate();
    const navigate = useCallback((...args) => {
        if (typeof args[0] === 'number') {
            const delta = args[0];
            setTimeout(() => navigateFn(delta), 0);
        }
        else {
            const to = args[0];
            const options = args[1];
            setTimeout(() => navigateFn(to, options), 0);
        }
    }, [navigateFn]);
    return (_jsxs(RouterContainer, { prefix: prefix, navigate: navigate, children: [pageRoutes.map((pageRoute) => {
                return (_jsx(Route, { path: pageRoute.pathname, element: pageRoute.component }, pageRoute.pathname));
            }), _jsx(Route, { element: FallbackComponent, path: "*" })] }));
};
